import React from "react";
import { css } from "@emotion/react";
import { breakpoints as bp, colors } from "../theme";
import { Button } from ".";
import animateScrollTo from "animated-scroll-to";

function Card({ children, title, active, target, ...rest }) {
  return (
    <div
      {...rest}
      css={css`
        background-color: white;
        border-radius: 6px;
        border: 1px solid #ddd;
        cursor: pointer;
        padding: 30px;
        flex: 1;
        margin: 0 10px;
        transition: transform 0.2s;
        box-shadow: 0px 10px 13px -7px rgba(0, 0, 0, 0.1),
          5px 5px 15px 5px rgba(0, 0, 0, 0);

        &:hover {
          transform: scale(1.05);
        }
      `}
    >
      <h2
        css={css`
          color: ${colors.blue[400]};
          margin: 0;
          font-size: 1.4rem;
          text-align: center;
        `}
      >
        {title}
      </h2>
      <p
        css={css`
          font-size: 0.85rem;
          text-align: left;
          min-height: 140px; ;
        `}
      >
        {children}
      </p>

      <Button
        onClick={() => {
          animateScrollTo(document.querySelector(target));
        }}
      >
        Learn More
      </Button>
    </div>
  );
}

function CardContainer({ children }) {
  return (
    <>
      <div
        css={css`
          display: flex;
          max-width: 1220px;
          margin: auto;
          justify-content: space-between;
          position: relative;
          transform: translateY(-100px);
          text-align: center;

          @media (max-width: ${bp.small}px) {
            transform: translateY(0px);
            display: none;
          }
        `}
      >
        {children}
      </div>
    </>
  );
}

Card.Container = CardContainer;
export default Card;
