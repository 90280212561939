import React from "react";
import { css } from "@emotion/react";

function Divider(props) {
  return (
    <hr
      css={css`
        height: 2px;
        width: 100%;
        margin: 35px 0 75px;
        border: 0;
        background-color: #ddd;
      `}
    />
  );
}

export default Divider;
