import React from "react";
import { css } from "@emotion/react";
import { Logo, Panel, Container, Flex } from ".";
import { colors, breakpoints as bp } from "../theme";

function Hero() {
  return (
    <Panel
      css={css`
        height: 600px;
        margin-top: 0;
        @media (max-width: ${bp.small}px) {
          height: auto;
          padding-top: 110px;
        }
      `}
    >
      <Container size="large">
        <Flex alignItems="center">
          <Flex.Column>
            <h2>Insurance for Home, Auto and Business.</h2>
            <p
              css={css`
                -webkit-font-smoothing: antialiased;
                -moz-osx-font-smoothing: grayscale;
                line-height: 1.9rem;
                margin: 30px auto;
                font-size: 1.2rem;
                font-weight: 400;
                max-width: 800px;
                @media (max-width: ${bp.small}px) {
                  font-size: 0.95rem;
                  line-height: 1.7rem;
                }
              `}
            >
              Now offering coverage including Auto, Homeowners, Life and
              Commercial Insurance. Family owned and operated for 20 years.
            </p>
            <p>
              <a
                href="#"
                css={css`
                  // border: 3px solid white;
                  border-radius: 30px;
                  background-color: ${colors.gold};
                  padding: 14px;
                  color: #333;
                  text-shadow: 2px 2px rgba(255, 255, 255, 0.2);
                  box-shadow: 0px 1px 1px 1px rgba(0, 0, 0, 0.9);
                  display: block;
                  margin-top: 45px;
                  font-size: 1.2rem;
                  text-align: center;
                  width: 190px;
                `}
              >
                Call us today!
              </a>
            </p>
          </Flex.Column>
          <Flex.Column
            css={css`
              display: flex;
              align-items: center;
              justify-content: center;
            `}
          >
            <Logo
              animateIn
              size={200}
              color="white"
              css={css`
                @media (max-width: ${bp.small}px) {
                  display: none;
                }
              `}
            />
          </Flex.Column>
        </Flex>
      </Container>
    </Panel>
  );
}

export default Hero;
