import { css } from "@emotion/react";
import React from "react";
import { breakpoints as bp } from "../theme";

function Heading({ title, children }) {
  return (
    <div css={{ textAlign: "center" }}>
      <h1
        css={css`
          margin: 0px auto 30px;
          font-size: 2.7rem;
          font-weight: 400;

          @media (max-width: ${bp.small}px) {
            font-size: 1.69em;
            margin-top: 30px;
          }
        `}
      >
        {title}
      </h1>
      {children && (
        <p
          css={css`
            color: #444;
            font-size: 1.2rem;
            font-weight: 300;
            line-height: 1.7rem;
            margin: 0px auto 60px;
            max-width: 700px;

            @media (max-width: ${bp.small}px) {
              font-size: 1rem;
              line-height: 1.4rem;
              padding: 0 10px;
              margin-bottom: 40px;
            }
          `}
        >
          {children}
        </p>
      )}
    </div>
  );
}

export default Heading;
