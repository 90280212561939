import React from "react";
import PropTypes from "prop-types";
import { css } from "@emotion/react";

function FlexColumn({ children, ...rest }) {
  return (
    <div
      css={css`
        flex: 1;
      `}
      {...rest}
    >
      {children}
    </div>
  );
}

FlexColumn.propTypes = {
  children: PropTypes.node.isRequired,
};

FlexColumn.defaultProps = {};

export default FlexColumn;
