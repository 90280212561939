export const colors = {
  blue: {
    300: "#61A0AF",
    400: "#0D1552",
    500: "#090f39",
  },

  gray: {
    100: "#EEF2F4",
    300: "#333333",
    500: "#646165",
  },

  green: {
    100: "#BEEFDB",
    300: "#6BAB90",
    500: "#55917F",
  },

  orange: "#F7931E",
  gold: "#F4AC32",
  white: "#FFFFFF",
};

export const layout = {
  container: {
    small: 600,
    default: 900,
    large: 1200,
    xlarge: 1400,
  },
};

export const typography = {
  body: 16,
  caption: 12,
  p: 16,
  h1: 48,
  h2: 36,
  h3: 26,
  h4: 20,
  h5: 18,
};

export const breakpoints = {
  mobile: 500,
  small: 700,
  medium: 900,
  large: 1200,
  xlarge: 1900,
};

/**
 * can be used in css like this:
 * mq(500) {} // 500px breakpoint
 * or
 * mq.mobile {} // whatever mobile breakpoint is set to.
 */
const mq = (size) => `@media (min-width: ${size}px)`;
Object.keys(breakpoints).forEach((name) => (mq[name] = mq(breakpoints[name])));

export { mq };
