import React, { useState, useEffect } from "react";
import { css } from "@emotion/react";
import PropTypes from "prop-types";

function Logo({ color, size, animateIn, ...rest }) {
  const [hasAnimated, setHasAnimated] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      if (!hasAnimated && animateIn) setHasAnimated(true);
    }, 300);
  }, []);

  return (
    <div
      css={css`
        display: inline-block;
        width: ${size}px;
        transition: all 0.7s;
        transform: translateY(${hasAnimated || !animateIn ? 0 : 20}px);
        opacity: ${hasAnimated || !animateIn ? 1 : 0};
      `}
      {...rest}
    >
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 134.6 134.59">
        <path
          d="M75,56.83c8.6,1.56,14.66,5.65,14.66,13.43,0,5.08-3.2,9.83-7.86,12.45-4.18,2.3-9.5,3.44-15.56,3.44H43.27v-3c6.31-.65,7-1.06,7-7.94V44c0-6.88-1.07-7.62-6.88-8v-3H68.25c6.23,0,10.16.82,13.43,2.87a10.51,10.51,0,0,1,5,9.09C86.68,51.67,80.29,55.77,75,56.83Zm-10.4-1.06c7.78,0,10.64-3.36,10.64-9.42,0-7.12-4.66-9.83-9.17-9.83a7.84,7.84,0,0,0-3.85.66c-.9.57-.82,1.31-.82,3V55.77ZM61.37,75c0,5.9,2.71,7.7,7.13,7.62s8.93-4,8.93-11.63c0-7.94-4-11.71-13.19-11.71H61.37Z"
          fill="#f4ac32"
        />
        <path
          d="M67.29,134.59a5.6,5.6,0,0,1-3.13-1C23.7,106.41.33,65.74,0,22a5.61,5.61,0,0,1,4.17-5.47L65.86.19a5.62,5.62,0,0,1,2.88,0l61.69,16.36A5.64,5.64,0,0,1,134.6,22c-.3,43.69-23.7,84.39-64.16,111.61A5.76,5.76,0,0,1,67.29,134.59ZM11.34,26.28c1.71,37.18,21.86,71.76,56,95.9,34.08-24.14,54.24-58.72,55.94-95.9L67.29,11.42Z"
          fill="white"
        />
      </svg>
    </div>
  );
}

Logo.propTypes = {
  color: PropTypes.string,
  size: PropTypes.number,
  animateIn: PropTypes.bool,
};

Logo.defaultProps = {
  color: "#333333",
  size: 36,
  animateIn: false,
};

export default Logo;
