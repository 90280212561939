import React from "react";
import PropTypes from "prop-types";
import { css } from "@emotion/react";
import FlexColumn from "./flex-column";
import { breakpoints as bp } from "../theme";

function Flex({ alignItems, children, ...rest }) {
  return (
    <div
      css={css`
        display: flex;
        align-items: ${alignItems};

        @media (max-width: ${bp.small}px) {
          display: block;
        }
      `}
      {...rest}
    >
      {children}
    </div>
  );
}

Flex.Column = FlexColumn;

Flex.propTypes = {
  children: PropTypes.node.isRequired,
  alignItems: PropTypes.string,
};

Flex.defaultProps = {
  alignItems: "flex-start",
};

export default Flex;
