import React from "react";
import { css } from "@emotion/react";
import { colors, breakpoints as bp } from "../theme";

function IconList({ children }) {
  return (
    <div
      css={css`
        margin: 95px auto 25px;
        background-color: #fafafa;
        padding: 45px;
      `}
    >
      <div
        css={css`
          display: flex;
          max-width: 1280px;
          margin: auto;
          @media (max-width: ${bp.small}px) {
            display: block;
          }
        `}
      >
        {children}
      </div>
    </div>
  );
}

function IconListItem({ title, children, icon: Icon }) {
  return (
    <div
      css={css`
        margin: 0 20px;
        flex: 1;

        h3 {
          line-height: 1.3rem;
          font-size: 1rem;
          text-transform: uppercase;
          text-align: center;
          letter-spacing: 0.036rem;
          height: 50px;
        }

        p {
          font-size: 0.9rem;
          text-align: center;
        }
      `}
    >
      <div
        css={css`
          display: flex;
          justify-content: center;
          align-items: center;
          height: 90px;
          width: 90px;
          margin: auto;
          border-radius: 20px;
          color: ${colors.blue[400]};

          path {
            stroke: ${colors.blue[400]};
          }
        `}
      >
        <Icon size={60} />
      </div>
      <h3>{title}</h3>
      <p>{children}</p>
    </div>
  );
}

IconList.Item = IconListItem;

export default IconList;
