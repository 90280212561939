import React from "react";
import { css } from "@emotion/react";
import { breakpoints as bp, colors } from "../theme";

function SplitRow({
  children,
  dark,
  image,
  flip,
  title,
  smallMobileImage,
  ...rest
}) {
  const renderImageCell = () => (
    <div
      css={css`
        flex: 0 0 50%;
        position: relative;
        @media (max-width: ${bp.small}px) {
          flex: 1;
        }
      `}
    >
      <img
        src={image}
        css={css`
          max-width: ${smallMobileImage ? "200px" : "100%"};
          box-shadow: 0px 10px 13px -7px rgba(0, 0, 0, 0.4),
            5px 5px 6px -5px rgba(0, 0, 0, 0);
          position: relative;
          z-index: 2;
        `}
      />
      <span
        css={css`
          display: none;
          background-color: ${colors.green[300]};
          height: 200px;
          width: 370px;
          top: 60px;
          left: -30px;
          position: absolute;
          z-index: 1;

          @media (max-width: ${bp.small}px) {
            display: none;
          }
        `}
      />
    </div>
  );

  const renderContentCell = () => (
    <div
      css={css`
        flex: 1;
        display: flex;
        align-items: center;
        justify-content: center;
        color: ${dark ? "white" : "inherit"};
        background: ${dark ? "#55917F" : "transparent"};
        padding-right: ${flip ? "65px" : "0px"};
        padding-left: ${flip ? "0px" : "0px"};

        @media (max-width: ${bp.small}px) {
          padding: 0px;
        }
      `}
    >
      <div
        css={css`
          padding: 0 55px 10px;
          @media (max-width: ${bp.small}px) {
            padding: 25px;
          }
        `}
      >
        {children}
      </div>
    </div>
  );

  return (
    <div
      css={css`
        display: flex;
        max-width: 1200px;
        margin: auto;
        align-items: center;
        font-size: 0.95rem;

        @media (max-width: ${bp.small}px) {
          display: block;
          max-width: 100%;
        }
      `}
      {...rest}
    >
      {flip && (
        <React.Fragment>
          {renderContentCell()}
          {renderImageCell()}
        </React.Fragment>
      )}

      {!flip && (
        <React.Fragment>
          {renderImageCell()}
          {renderContentCell()}
        </React.Fragment>
      )}
    </div>
  );
}

SplitRow.defaultProps = {
  flip: false,
  smallMobileImage: false,
};

export default SplitRow;
