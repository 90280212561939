import React from "react";
import { Global, css } from "@emotion/react";
import { colors, breakpoints as bp } from "../theme";

import "@fontsource/open-sans";
import "@fontsource/open-sans/300.css";
import "@fontsource/open-sans/600.css";
import "@fontsource/open-sans/700.css";

function GlobalStyles(props) {
  return (
    <Global
      styles={css`
        *,
        *::before,
        *::after {
          box-sizing: border-box;
        }

        body {
          color: #333;
          font-family: "Open Sans";
          font-size: 16px;
          margin: 0;
          padding: 0;
        }

        a {
          color: ${colors.blue[300]};
          font-weight: 700;
          text-decoration: none;
        }

        h1,
        h2,
        h3,
        h4,
        h5 {
          line-height: 2.2rem;
        }

        h1 {
          font-size: 2rem;
        }

        h2 {
          font-size: 1.7rem;
          margin-top: 0;
        }

        h3 {
          font-size: 1.2rem;

          @media (max-width: ${bp.small}px) {
            line-height: 1.5rem;
          }
        }

        h4 {
          font-size: 1rem;
          line-height: 1.4rem;
        }
      `}
    />
  );
}

export default GlobalStyles;
