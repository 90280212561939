import React from "react";
import PropTypes from "prop-types";
import { css } from "@emotion/react";
import { layout } from "../theme";

function Container({ size, children, ...rest }) {
  return (
    <div
      css={css`
        max-width: ${layout.container[size]}px;
        margin: auto;
      `}
      {...rest}
    >
      {children}
    </div>
  );
}

Container.propTypes = {
  children: PropTypes.node.isRequired,
  size: PropTypes.oneOf(["small", "default", "large", "xlarge"]),
};

Container.defaultProps = {
  size: "default",
};

export default Container;
