import React from "react";
import PropTypes from "prop-types";
import { css } from "@emotion/react";
import { breakpoints as bp } from "../theme";

function Panel({ children, color, ...rest }) {
  return (
    <div
      css={css`
        align-items: center;
        background: ${color || "rgb(94, 99, 126)"};
        background: ${color ||
        "linear-gradient(120deg, rgba(10, 19, 81, 1) 34%, rgba(24, 33, 84, 1) 74%)"};
        color: ${color ? "inherit" : "white"};
        display: flex;
        justify-content: center;
        margin: 0;
        padding: 120px 15px;
        @media (max-width: ${bp.small}px) {
          padding: 70px 30px;
        }
      `}
      {...rest}
    >
      {children}
    </div>
  );
}

Panel.propTypes = {
  children: PropTypes.node.isRequired,
};

Panel.defaultProps = {};

export default Panel;
