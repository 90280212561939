import React from "react";
import { css } from "@emotion/react";
import { breakpoints as bp, colors } from "../theme";
import { BsFillTelephoneFill } from "react-icons/bs";
import { MdEmail } from "react-icons/md";
import { Container, Logo } from ".";

const TextWithIcon = ({ icon: Icon, text, children, ...rest }) => (
  <span
    css={css`
    margin: 9px auto;
      display: flex;
      svg {
        margin-right: 20px;
      }
      a { color: ${colors.gold}; font-weight: 400; font-size: 0.92rem;}
      {...rest}
    `}
  >
    <Icon size={20} />

    <span
      css={css`
        @media (max-width: ${bp.small}px) {
          display: none;
        }
      `}
    >
      {text || children}
    </span>
  </span>
);

function Header() {
  return (
    <header
      css={css`
        background-color: ${colors.blue[500]};
        color: white;
        padding: 18px 20px;
        position: absolute;
        width: 100%;
        z-index: 5;

        a {
          color: white;
          font-weight: 400;
          font-size: 0.9rem;
          svg {
            color: white;
          }
        }

        @media (max-width: ${bp.small}px) {
          svg {
            width: 36px;
          }
        }
      `}
    >
      <Container
        size="large"
        css={css`
          display: flex;
          align-items: center;
          justify-content: space-between;
        `}
      >
        <section
          css={css`
            display: flex;
            align-items: center;

            em {
              display: block;
              font-weight: 700;
              font-size: 1.5rem;
              font-style: normal;
              margin-top: -4px;

              @media (max-width: ${bp.small}px) {
                font-size: 1rem;
                margin-top: 0px;
              }
            }
          `}
        >
          <Logo size={64} color="white" />

          <span
            css={css`
              display: inline-block;
              font-size: 1.04rem;
              margin-left: 20px;
              line-height: 1.6rem;

              @media (max-width: ${bp.small}px) {
                font-size: 0.84rem;
                line-height: 1.3rem;
                margin-top: 0;
                margin-left: 0;
              }
            `}
          >
            <em>Brokers</em> Insurance LLC
          </span>
        </section>

        <section
          css={css`
            @media (max-width: ${bp.small}px) {
              display: flex;
            }
          `}
        >
          <a href="tel:407-862-6464">
            <TextWithIcon text="407-862-6464" icon={BsFillTelephoneFill} />
          </a>

          <a href="mailto:marc@orlandobrokersinsurance.com">
            <TextWithIcon
              icon={MdEmail}
              text="marc@orlandobrokersinsurance.com"
            >
              marc@orlandobrokersinsurance.com
            </TextWithIcon>
          </a>
        </section>
      </Container>
    </header>
  );
}

export default Header;
