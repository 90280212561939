import React, { useState, Children } from "react";
import { css } from "@emotion/react";
import { breakpoints as bp, colors } from "../theme";

function Tabs({ children }) {
  const [active, setActive] = useState(0);
  const contentMap = {};

  return (
    <>
      <div
        css={css`
          display: flex;
          justify-content: center;
          margin-bottom: 35px;

          @media (max-width: ${bp.small}px) {
            display: block;
          }
        `}
      >
        {Children.map(children, (c, index) => {
          contentMap[index] = children;
          return React.cloneElement(c, {
            id: index,
            active: active === index,
            onClick: () => {
              c.props.onClick();
              setActive(index);
            },
          });
        })}
      </div>
    </>
  );
}

function TabItem({ label, onClick, active }) {
  return (
    <li
      css={css`
        list-style: none;
        padding: 0;
      `}
    >
      <button
        type="button"
        onClick={onClick}
        css={css`
          appearance: none;
          border: 0;
          border-bottom: 3px solid ${colors.blue[400]};
          background-color: ${active ? colors.blue[400] : colors.gray[100]};
          color: ${active ? "white" : colors.blue[400]};
          font-family: "Open Sans";
          font-size: 0.89rem;
          cursor: pointer;
          transition: all 0.4s;
          text-transform: uppercase;
          letter-spacing: 0.025rem;
          font-weight: 700;
          padding: 15px 25px;
          position: relative;
          margin: 0 15px;
          width: 250px;

          @media (max-width: ${bp.small}px) {
            width: calc(100% - 30px);
          }

          &:after {
            display: ${active ? "block" : "none"};
            position: absolute;
            content: "";
            bottom: -10px;
            left: calc(50% - 5px);
            width: 0;
            height: 0;
            border-style: solid;
            border-width: 10px 10px 0 10px;
            border-color: ${colors.blue[400]} transparent transparent
              transparent;
          }
        `}
      >
        {label}
      </button>
    </li>
  );
}

Tabs.Item = TabItem;
export default Tabs;
