import React from "react";
import { css } from "@emotion/react";
// import PropTypes from "prop-types";

function List({ children }) {
  return (
    <ul
      css={css`
        list-style: none;
        padding: 0;
        margin: 25px auto;
        font-size: 0.86rem;
      `}
    >
      {children}
    </ul>
  );
}

List.propTypes = {};
List.defaultProps = {};

function ListItem({ children }) {
  return (
    <li
      css={css`
        padding: 10px 10px;
        background-color: white;
        &:nth-child(odd) {
          background-color: #f3f3f3;
        }
      `}
    >
      {children}
    </li>
  );
}

ListItem.propTypes = {};
ListItem.defaultProps = {};

List.Item = ListItem;
export default List;
