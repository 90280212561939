import { css } from "@emotion/react";
import React from "react";
import { colors } from "../theme";

function Button({ children, ...rest }) {
  return (
    <button
      type="button"
      css={css`
        appearance: none;
        border: 0;
        // border: 3px solid ${colors.blue[400]};
        border-radius: 20px;
        color: #533e1b;
        box-shadow: 0px 3px 0px 0px #a08a31;
        cursor: pointer;
        padding: 14px 6px 11px;
        display: block;
        background-color: ${colors.gold};
        width: 100%;
        font-size: 0.77rem;
        text-transform: uppercase;
        font-weight: 600;
        margin: 0 auto;
        max-width: 150px;
      `}
      {...rest}
    >
      {children}
    </button>
  );
}

export default Button;
