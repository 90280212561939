import React, { useState } from "react";
import { Helmet } from "react-helmet";
import { css } from "@emotion/react";

import {
  Container,
  GlobalStyles,
  Header,
  Flex,
  Panel,
  Hero,
  SplitRow,
  Card,
  Tabs,
  Footer,
  Heading,
  IconList,
  List,
} from "../components";
import { FaHouseDamage, FaWater } from "react-icons/fa";
import { GrBike } from "react-icons/gr";
import { GiBrokenBone } from "react-icons/gi";
import image from "../images/brands@2x.png";
import familyImage from "../images/stock-family.jpeg";
import autoImage from "../images/stock-auto.jpeg";
import motoImage from "../images/stock-motorcycle.jpg";
import houseImage from "../images/stock-house.jpg";
import buildingImage from "../images/stock-building.jpg";
import { colors } from "../theme";

const IndexPage = () => {
  const [autoType, setAutoType] = useState("car");

  return (
    <>
      <GlobalStyles />
      <Header />
      <Hero />

      <Helmet>
        <meta charSet="utf-8" />
        <title>
          Auto, Life, Homeowners and Commercial Insurance in Orlando, Florida -
          Brokers Insurance LLC. Family owned and operated for over 20 years.
        </title>
      </Helmet>

      <Card.Container>
        <Card title="Auto" target="#auto-insurance-section">
          Insurance covering your mode of transportation is designed to provide
          financial protection for your family in the event of unforeseen
          damages, injuries, and potential liabilities.
        </Card>

        <Card title="Life" target="#life-insurance-section">
          Coverage that can help with peace of mind comes from knowing that when
          your time runs out, you will continue to contribute to your family by
          better protecting their financial future.
        </Card>

        <Card title="Homeowners" target="#homeowners-insurance-section">
          In Central Florida our experiences with Hurricanes provide first hand
          evidence of the importance of homeowners coverage.
        </Card>

        <Card title="Commercial" target="#commercial-insurance-section">
          Commercial insurance quotes can be tough to come by and it's important
          to know exactly what type of commercial insurance coverage you need
          and don't.
        </Card>
      </Card.Container>

      <Container size="large" id="auto-insurance-section">
        <Heading title="Auto Insurance">
          Coverage for yourself, your vehicle, other people's property, your
          passengers, fellow drivers, and pedestrians.
        </Heading>
        <Tabs>
          <Tabs.Item
            label="Automobile"
            onClick={() => setAutoType("car")}
            active
          />
          <Tabs.Item
            label="Motorcycle"
            onClick={() => setAutoType("motorcycle")}
          />
          <Tabs.Item
            label="Additional Coverage"
            onClick={() => setAutoType("other")}
          />
        </Tabs>

        {autoType === "car" && (
          <SplitRow image={autoImage}>
            <>
              <h3 id="auto-insurance-section">Automobile</h3>
              <p>
                Our agents are ready to help you find coverage based on your
                individual needs. We can help find auto insurance with the
                options you would like at a competitive price. Policy options
                for your vehicle include but are not limited to:
              </p>
              <List>
                <List.Item>Bodily Injury Liability</List.Item>
                <List.Item>Property Damage Liability</List.Item>
                <List.Item>Collision Auto Coverage</List.Item>
                <List.Item>Comprehensive Auto Coverage</List.Item>
                <List.Item>Medical Payments Auto Coverage</List.Item>
                <List.Item>Personal Injury Protection</List.Item>
                <List.Item>Uninsured Motorist Auto Coverage</List.Item>
                <List.Item>Rental Reimbursement</List.Item>
                <List.Item>Towing</List.Item>
              </List>
            </>
          </SplitRow>
        )}
        {autoType === "motorcycle" && (
          <SplitRow image={motoImage}>
            <>
              <h3>Motorcycle</h3>
              <p>
                We are ready to help you personalize your motorcycle policy
                based on your needs. We can help you find coverage that matches
                your needs at a competitive price. Motorcycle Insurance policy
                options you may choose to consider include but are not limited
                to:
              </p>
              <List>
                <List.Item>
                  Bodily Injury Liability Motorcycle Coverage
                </List.Item>
                <List.Item>Motorcycle Guest Passenger Liability</List.Item>
                <List.Item>
                  Property Damage Liability Motorcycle Coverage
                </List.Item>
                <List.Item>Collision Motorcycle Coverage</List.Item>
                <List.Item>Comprehensive Motorcycle Coverage</List.Item>
                <List.Item>Personal Injury Protection</List.Item>
                <List.Item>Uninsured Motorist Motorcycle Coverage</List.Item>
                <List.Item>
                  Custom Parts & Equipment or Accessories Coverage
                </List.Item>
                <List.Item>Towing</List.Item>
              </List>
            </>
          </SplitRow>
        )}

        {autoType === "other" && (
          <div
            css={css`
              text-align: center;
              max-width: 460px;
              margin: 60px auto 10px;
            `}
          >
            <h3>Additional Coverage</h3>
            <p
              css={css`
                margin-bottom: 30px;
              `}
            >
              At Brokers Insurance LLC, we can also provide coverage for your
              Boat, Recreational Vehicle and more!
            </p>
            <a href="tel:407-862-6464">Call us today at (407) 862-6464</a>
          </div>
        )}
        <br />
        <br />
        <br />
      </Container>

      <Panel
        color="#f6fbff"
        css={css`
          margin-top: 25px;
        `}
      >
        <Container size="large" id="life-insurance-section">
          <Heading title="Life Insurance">
            You may find peace of mind by knowing that when your time runs out,
            you will continue to contribute to your family by protecting their
            financial future.
          </Heading>
          <br />
          <SplitRow image={familyImage} flip>
            <h3 css={{ lineHeight: "1.7rem", marginBottom: 25 }}>
              Life insurance provides you with an opportunity to plan ahead
            </h3>

            <p>
              At Brokers Insurance LLC in Orlando we know it is difficult to
              consider preparing for a time when you can no longer provide for
              your family and loved ones. There comes a time in all our lives
              when our earning potential is cut short.
            </p>

            <h4 css={{ marginTop: 50 }}>Provide a financial future.</h4>

            <p>
              This coverage can help you find the peace of mind that comes from
              knowing that when your time runs out, you will continue to
              contribute to your family by protecting their financial future.
            </p>
          </SplitRow>
        </Container>
      </Panel>

      <Panel color="white">
        <Container size="large" id="homeowners-insurance-section">
          <Heading title="Homeowners">
            Brokers insurance LLC has the experience to help you match your
            homeowners insurance needs with some of the top carriers that offer
            competitive coverage at a low cost!
          </Heading>

          <SplitRow image={houseImage}>
            <h3>Insuring Your Home</h3>
            <p>
              Coverage should you need to rebuild your home. Our licensed agents
              can provide you with options for limits and deductibles on your
              policy. We are here to help you find coverage for your home at a
              competitive price.
            </p>
            <h4>
              Our experiences with Hurricanes provide first hand evidence of the
              importance of homeowners coverage
            </h4>
            <p>
              Fires, floods, tornados, and sinkholes are additional potential
              disasters Central Florida Homeowners have the opportunity to be
              prepared for with the right coverage.
            </p>
            <h4>
              We recognize that in Orlando not all homeowners policies are
              created equal.
            </h4>
            <p>
              We are prepared to work with you to provide a personalized
              homeowners insurance policy with the coverage options you would
              like for your dwelling and your personal possessions.
            </p>
          </SplitRow>

          <IconList>
            <IconList.Item title="Insuring Your Home" icon={FaHouseDamage}>
              We listen to you and your needs and recommend coverage that match
              your individual risk.
            </IconList.Item>

            <IconList.Item
              title="Insuring Your Personal Property"
              icon={GrBike}
            >
              We work with you to help you determine what type(s) of coverage
              match your needs in order to insure your personal items.
            </IconList.Item>

            <IconList.Item title="Liability Coverage" icon={GiBrokenBone}>
              Provide better protection against injury or property damage. Stay
              better protected against claims that could result in devastating
              economic losses.
            </IconList.Item>

            <IconList.Item title="Flood Insurance" icon={FaWater}>
              <p>
                Flooding is{" "}
                <a
                  target="_blank"
                  href="http://www.fema.gov/hazard/flood/index.shtml"
                >
                  cited by FEMA
                </a>{" "}
                as a prominent hazard facing homeowners across the United
                States.
              </p>
            </IconList.Item>
          </IconList>
        </Container>
      </Panel>

      <Panel color="#f6fbff" css={{ paddingBottom: 100 }}>
        <Container size="large" id="commercial-insurance-section">
          <Heading title="Commercial Insurance">
            Commercial insurance quotes can be tough to come by and it's
            important to know exactly what type of commercial insurance coverage
            you need and don't.
          </Heading>

          <SplitRow image={buildingImage} flip>
            <h3 css={{ lineHeight: "1.7rem", marginBottom: 25 }}>
              Safeguarding Your Business
            </h3>

            <p>
              Business owners have a variety of insurance policies to choose
              from that can provide protection for their business. Our agents at
              Brokers Insurance LLC in Orlando can help you acquire a life
              insurance policy that can aid your heirs and or your surviving
              business partners.
            </p>

            <p>
              We offer many years of experience in the industry right here in
              Orlando that have hundreds of companies they can write policies
              for. This enables Brokers Insurance to get you the coverage you
              need at the best rates!
            </p>
          </SplitRow>
        </Container>
      </Panel>

      <Panel color="white">
        <Container size="large">
          <Flex alignItems="center">
            <Flex.Column>
              <img src={image} style={{ maxWidth: "100%" }} />
            </Flex.Column>
            <Flex.Column>
              <div css={{ paddingLeft: 40 }}>
                <h2>Great Company Partners</h2>
                <p>
                  At Brokers, we work with many different companies in order to
                  get you one of the better deals on the market.
                </p>
                <p>Here's just a sample of the big brands we work with</p>
              </div>
            </Flex.Column>
          </Flex>
        </Container>
      </Panel>

      <Footer />
    </>
  );
};

export default IndexPage;
