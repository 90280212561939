import React from "react";
import GoogleMapReact from "google-map-react";
import { css } from "@emotion/react";
import { Logo } from ".";

const AnyReactComponent = () => (
  <div
    css={css`
      background-color: white;
      display: inline-flex;
      justify-content: center;
      align-items: center;
      height: 50px;
      width: 50px;
      border-radius: 50%;
      border: 1px solid #ccc;
      box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.2);
      padding-top: 5px;
    `}
  >
    <Logo size={28} color="#222" />
  </div>
);

function Map({ center, zoom }) {
  return (
    <div
      css={css`
        width: 100%;
        height: 200px;
      `}
    >
      <GoogleMapReact
        yesIWantToUseGoogleMapApiInternals
        defaultCenter={center}
        defaultZoom={zoom}
        options={{
          mapTypeControl: false,
          zoomControl: false,
          minZoom: 15,
          maxZoom: 15,
          rotateControl: false,
          scaleControl: false,
          fullscreenControl: false,
        }}
        bootstrapURLKeys={{
          key: "AIzaSyCMM5AqjIMLl9A3qrHS9bYOoV_9qTUeUKo",
          language: "en",
        }}
      >
        <AnyReactComponent
          lat={28.6496196}
          lng={-81.3890877}
          text={"SUP BRO"}
        />
      </GoogleMapReact>
    </div>
  );
}

Map.defaultProps = {
  center: { lat: 28.6496196, lng: -81.3890877 },
  zoom: 15,
};

export default Map;
